@use "../global" as g;

.p-contact {


  .contact-sec {
    margin-bottom: 15rem;

    @include g.mq-down(md) {
      margin-bottom: 20rem;
    }

    .inner {
      width: 100rem;
      margin: 0 auto;

      @include g.mq-down(md) {
        width: calc(100% - 7.2rem);
      }

      .intro {
        width: 46.2rem;
        float: left;

        @include g.mq-down(md) {
          width: 100%;
          float: none;
          margin-bottom: 6rem;
        }
      }

      .form {
        float: right;
        width: 53.8rem;

        @include g.mq-down(md) {
          width: 100%;
          float: none;
        }
      }

      .company {
        width: 46.2rem;
        float: left;
        font-weight: 700;
        margin-top: 6rem;

        @include g.mq-down(md) {
          width: 100%;
          float: none;
          margin-top: 12rem;

        }

        .btn {
          margin-top: 4rem;
          display: block;
          width: 23rem;
          font-weight: 700;

          @include g.mq-down(md) {
            width: 46rem;
          }
        }
      }
    }
  }
}

.p-contact-thanks {
  .contact-sec {
    .inner {

      .intro,
      .compamy {
        float: none;
      }

      #mfp_thanks {
        display: none !important;
      }
    }
  }
}
