@use "../global" as g;

.pagehead-sec {
  padding: 8.5rem 0 12rem;

  @include g.mq-down(md) {
    padding: 8rem 0 6rem;
  }

  .inner {
    width: 128rem;
    margin: 0 auto;

    @include g.mq-down(md) {
      width: calc(100% - 7.2rem);
    }

    .title {
      font-size: 1.8rem;
      font-weight: 700;
      text-align: center;

      @include g.mq-down(md) {
        font-size: 2.8rem;
      }
    }
  }
}
