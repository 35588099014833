@use "../global" as g;

.p-top {
  .mv-sec {
    padding: 7rem 0 0;

    @include g.mq-down(md) {
      padding: 6rem 0 0;
    }

    .inner {
      width: 128rem;
      margin: 0 auto;

      @include g.mq-down(md) {
        width: calc(100% - 7.2rem);
      }

      figure {
        border-radius: 2rem;
        overflow: hidden;
      }
    }
  }

  .about-sec {
    padding: 14rem 0 0;

    .inner {
      width: 128rem;
      margin: 0 auto;

      @include g.mq-down(md) {
        width: calc(100% - 7.2rem);
      }

      .midashi {
        font-size: 1.8rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 4.8rem;

        @include g.mq-down(md) {
          font-size: 2.8rem;
        }
      }

      .catch {
        text-align: center;
        font-size: 2.1rem;
        margin-bottom: 2.4rem;

        @include g.mq-down(md) {
          font-size: 3.2rem;
          margin-bottom: 4rem;
        }
      }

      .read {
        text-align: center;

        @include g.mq-down(md) {
          text-align: left;
          text-align: justify;
        }
      }
    }
  }

  .products-sec {
    padding: 14rem 0 0;

    .inner {
      width: 128rem;
      margin: 0 auto;

      @include g.mq-down(md) {
        width: calc(100% - 7.2rem);
      }

      .midashi {
        font-size: 1.8rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 4.8rem;

        @include g.mq-down(md) {
          font-size: 3.2rem;
          margin-bottom: 4rem;
        }
      }

      figure {
        border-radius: 2rem;
        overflow: hidden;
        margin-bottom: 5.2rem;
      }

      .komidashi {
        font-size: 1.8rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 4.8rem;

        @include g.mq-down(md) {
          font-size: 3.2rem;
          margin-bottom: 4rem;
        }
      }

      .outline {
        text-align: center;
        margin-bottom: 6.8rem;

        @include g.mq-down(md) {
          text-align: left;
        }
      }

      .btn {
        margin: 0 auto;
        width: 23rem;
        text-align: center;
        @include g.image-link();

        @include g.mq-down(md) {
          width: 39rem;
        }

      }
    }
  }
}
