@use "../global" as g;

.p-lenslineup {
  .pagehead-sec {
    @include g.mq-down(md) {
      display: none;
    }
  }

  .outline-sec {
    @include g.mq-down(md) {
      padding: 6rem 0 0;
    }

    .inner {
      width: 128rem;
      margin: 0 auto;

      @include g.mq-down(md) {
        width: calc(100% - 7.2rem);
      }

      .box {
        display: flex;
        justify-content: space-between;

        @include g.mq-down(md) {
          display: block;
        }

        .photo {
          width: 64rem;

          @include g.mq-down(md) {
            width: 100%;
            margin-bottom: 15rem;
          }
        }

        .text {
          width: 55.3rem;
          padding-right: 9.3rem;

          @include g.mq-down(md) {
            width: 100%;
            padding-right: 0;
          }

          .title {
            font-size: 2.8rem;
            text-align: center;
            margin-bottom: 4rem;
            font-weight: 700;
          }

          .catch {
            font-size: 2.1rem;
            margin-bottom: 3rem;

            @include g.mq-down(md) {
              font-size: 3.2rem;
              margin-bottom: 4rem;
              text-align: center;
            }
          }

          .read {
            line-height: 2.1;
            text-align: justify;

            .more {
              @include g.mq-up(md) {
                display: block !important;
              }


              @include g.mq-down(md) {
                display: none;
              }

            }
          }
        }
      }
    }
  }

  .lineup-sec {
    padding: 15rem 0 0;

    .inner {
      width: 128rem;
      margin: 0 auto;

      @include g.mq-down(md) {
        width: calc(100% - 7.2rem);
      }

      .description {
        width: 110rem;
        margin: 0 auto 15rem;

        @include g.mq-down(md) {
          width: 100%;
        }

        .midashi {
          font-size: 2.6rem;
          font-weight: 700;
          margin-bottom: 2rem;

          @include g.mq-down(md) {
            font-size: 2.8rem;
            text-align: center;
            margin-bottom: 4rem;
          }
        }

        .catch {
          font-size: 2.1rem;
          margin-bottom: 2rem;

          @include g.mq-down(md) {
            font-size: 3.2rem;
            margin-bottom: 4rem;
            text-align: center;
          }
        }

        .read {
          line-height: 2.1;
          text-align: justify;

          .more {
            @include g.mq-up(md) {
              display: block !important;
            }

            @include g.mq-down(md) {
              display: none;
            }

          }
        }
      }

      .list {
        display: flex;
        flex-wrap: wrap;

        @include g.mq-down(md) {
          justify-content: space-between;
        }

        .item {
          width: 39.6rem;
          margin-right: 4.6rem;
          margin-bottom: 18rem;

          @include g.mq-down(md) {
            width: 48%;
            margin-right: 0;
            margin-bottom: 9rem;
          }

          &:nth-child(3n) {
            margin-right: 0;
          }

          figure {
            width: 32rem;
            margin: 0 auto;
          }

          .caption {
            margin-top: -26rem;
            position: relative;
            z-index: 1;

            .name {
              font-size: 2.9rem;
              font-weight: 700;
              letter-spacing: 0.3rem;
              line-height: 1.4;

              @include g.mq-down(md) {
                font-size: 3.9rem;
              }
            }

            small {
              display: block;
            }

            .feature {
              margin-top: 4rem;

              p {
                text-indent: -2.3rem;
                margin-left: 2.3rem;
                font-weight: 500;
                font-size: 1.4rem;
                letter-spacing: 0.05rem;

                @include g.mq-down(md) {
                  font-size: 2.3rem;
                  letter-spacing: 0;
                  line-height: 1.6;

                  &+p {
                    margin-top: 1rem;
                  }

                }
              }
            }
          }

          &.gray {
            color: #646464;
          }

          &.aqua {
            color: #8282C8;
          }

          &.green {
            color: #649696;
          }

          &.brown {
            color: #966450;
          }

          &.khaki {
            color: #BE9B55;
          }

          &.chestnuts {
            color: #8F856C;
          }

          &.green2 {
            color: #869C79;
          }

          &.blue {
            color: #6584C2;
          }

          &.smoke {
            color: #323232;
          }
        }
      }
    }
  }
}
