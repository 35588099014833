@use "../global" as g;


html {
  @include g.rem-setting();
}

body {
  width: 100%;
  box-sizing: border-box;
  color: g.$text-color;
  font-family: "Noto Sans JP", "Noto Sans JP", Helvetica, Arial,
    "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka,
    "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0.15rem;
  font-feature-settings: "palt";

  @include g.mq-down(md) {
    font-size: 2.8rem;
  }

  -webkit-overflow-scrolling: touch;
  -webkit-print-color-adjust: exact;
  text-justify: inter-ideograph;
  -webkit-text-size-adjust: 100%;
}

//body.win {
//  font-family: Arial, 'Open Sans', 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro',
//    'Noto Sans JP', 游ゴシック体, 'Yu Gothic', YuGothic, メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック',
//    'MS PGothic', sans-serif;
//}
.stopscroll {
  overflow: hidden;
}

.helvetica {
  font-family: Helvetica, Arial, sans-serif;
}

.lato {
  font-family: "Lato", sans-serif;
}

.mincho {
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

a {
  color: inherit;
  text-decoration: none;
}

a.img-link {
  @include g.image-link_zoom();
}

img {
  width: 100%;
  height: auto;
  -webkit-backface-visibility: hidden;
}

.icon svg {
  width: 100%;
  height: 100%;
  display: block;
}

.italic {
  font-style: italic;
}

main {
  display: block;
}

.bold {
  font-weight: 700;
}

.ie .slick-dots li button:before {
  font-size: 20px;
}

*:focus {
  outline: none;
}



@include g.mq-up(md) {
  body {
    //min-width: 1180px;
  }

  .sp_view {
    display: none;
  }

  .pc_view {
    display: block;
  }
}


@include g.mq-down(md) {
  html {
    //overflow-x: hidden;
  }

  .sp_view {
    display: block;
  }

  .pc_view {
    display: none;
  }

  input[type="text"],
  input[type="search"],
  input[type="submit"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-border-radius: 0;
  }
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.clearfix:before {
  content: "";
  display: block;
  clear: both;
}

.clearfix {
  display: block;
}
