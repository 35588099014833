@use "../global" as g;

.btn {
  display: block;
  font-weight: 700;
  font-size: 1.4rem;
  padding: 0.7rem 0;
  text-align: center;
  background: repeating-linear-gradient(-135deg, #ddd, #ddd 0.1rem, #fff 0.1rem, #fff 0.2rem);

  @include g.mq-down(md) {
    font-weight: 400;
    font-size: 2.8rem;
    padding: 1rem 0;
    background: repeating-linear-gradient(-135deg, #ddd, #ddd 0.2rem, #fff 0.2rem, #fff 0.4rem);
  }
}

.acc-trigger {
  min-width: 36rem;
  width: fit-content;
  margin: 2rem auto 0;
  border: 0.4rem solid #000;
  padding: 0.1rem 10rem 0.1rem 2rem;
  position: relative;
  font-weight: 700;

  @include g.mq-up(md) {
    display: none;
  }

  &::before {
    content: "";
    width: 2.6rem;
    height: 0.4rem;
    background: #000;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 2rem;
  }

  &::after {
    content: "";
    width: 0.4rem;
    height: 2.6rem;
    background: #000;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 2+1.05rem;
  }

  &.is-active {
    margin-top: 8rem;

    &::after {
      content: none;
    }
  }
}
