@use "../breakpoint" as b;

$pcBaseWidth : 1440;
$pcBaseHeight : 810;
$spBaseWidth : 375*2;
$spBaseHeight : 667*2;


//min max vw/vh setting

@function pcvw($pixelsVw) {
  @return $pixelsVw / $pcBaseWidth * 100vw;
}

@function pcvh($pixelsVh) {
  @return $pixelsVh / $pcBaseHeight * 100vh;
}

@function spvw($pixelsVw) {
  @return $pixelsVw / $spBaseWidth * 100vw;
}

@function spvh($pixelsVh) {
  @return $pixelsVh / $spBaseHeight * 100vh;
}

///////////////////////////////////////////////////

@function pcminvw($width) {
  @return min(pcvw($width), $width * 1px);
}

@function pcminvh($height) {
  @return min(pcvw($height), $height * 1px);
}

@function pcmaxvw($width) {
  @return max(pcvw($width), $width * 1px);
}

@function pcmaxvh($height) {
  @return max(pcvw($height), $height * 1px);
}

@function spminvw($width) {
  @return min(spvw($width), $width * 1px);
}

@function spminvh($height) {
  @return min(spvw($height), $height * 1px);
}

@function spmaxvw($width) {
  @return max(spvw($width), $width * 1px);
}

@function spmaxvh($height) {
  @return max(spvw($height), $height * 1px);
}


//general rem setting

@mixin rem-setting() {

  font-size: 62.5%;


  @include b.mq-down(md) {
    font-size: 10/ $spBaseWidth * 100vw;
  }


  @include b.mq-between() {
    font-size: 10 / $pcBaseWidth * 100vw;
  }
}
