@use "../global" as g;

footer {
  border-top: 0.5rem solid #000;
  padding: 7rem 0 5rem;
  text-align: center;

  @include g.mq-down(md) {
    padding: 5rem 0 8rem;
  }

  small {
    a {
      text-decoration: underline;
    }
  }

  .logo {
    width: 9.6rem;
    margin: 4rem auto 0;
  }
}
