@use "../global" as g;

header {
  border-bottom: 0.5rem solid #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 100;

  .inner {
    width: 128rem;
    margin: 0 auto;
    position: relative;
    height: 9.6rem;

    @include g.mq-down(md) {
      width: calc(100% - 7.2rem);
    }

    .logo {
      width: 15rem;
      padding-top: 4rem;

      @include g.mq-down(md) {
        padding-top: 3.1rem;
        width: 23rem;
        line-height: 1;
      }
    }


    .menu-btn {
      position: absolute;
      top: 3.5rem;
      right: 0;
      display: flex;
      align-items: center;

      .text {
        display: inline-block;
        font-weight: 500;
        margin-right: 1rem;
        font-size: 2.6rem;
        line-height: 1;
      }

      @include g.mq-up(md) {
        display: none !important;
      }

      .menu-trigger,
      .menu-trigger span {
        display: inline-block;
        box-sizing: border-box;
        transition: all .2s;
      }

      .menu-trigger {
        width: 2.8rem;
        height: 2.3rem;
        position: relative;
      }

      .menu-trigger span {
        background-color: #000;
        width: 100%;
        height: 0.4rem;
        position: absolute;
        left: 0;
      }

      //.menu-trigger.active span {
      //    background-color: #FFFFFF;
      //}
      .menu-trigger span:nth-of-type(1) {
        top: 0;
        transform: translateY(0) rotate(0);
      }

      .menu-trigger span:nth-of-type(2) {
        top: 1rem;
      }

      .menu-trigger span:nth-of-type(3) {
        bottom: 0;
        transform: translateY(0) rotate(0);
      }

      /* Animation*/
      .menu-trigger.active span:nth-of-type(1) {
        top: 50%;
        transform: rotate(45deg);
      }

      .menu-trigger.active span:nth-of-type(2) {
        transform: scaleX(0);
      }

      .menu-trigger.active span:nth-of-type(3) {
        top: 50%;
        transform: rotate(-45deg);
      }
    }

    .menu {
      position: absolute;
      top: 4.5rem;
      right: 0;

      @include g.mq-up(md) {
        opacity: 1 !important;
        visibility: visible !important;

      }

      @include g.mq-down(md) {
        display: block;
        opacity: 0;
        visibility: hidden;
        top: 9.6rem;
        margin-left: -3.6rem;
        width: calc(100% + 7.2rem);
        height: calc(100vh - 9.6rem);
        right: auto;
        left: 0;
        background: #fff;
      }

      ul {
        display: flex;

        @include g.mq-down(md) {
          background: #000;
          color: #fff;
          padding: 9.6rem 0;
          display: block;
        }

        li {
          line-height: 1;
          font-weight: 700;
          display: flex;
          margin-left: 3.7rem;

          &:nth-of-type(1) {
            @include g.mq-up(md) {
              display: none;
            }
          }

          &:nth-last-of-type(1) {
            @include g.mq-down(md) {
              display: none;
            }
          }

          @include g.mq-down(md) {
            font-size: 3.9rem;
            justify-content: center;

            &+li {
              margin-top: 7rem;
            }
          }

          .icon {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.5rem;
            transform: translateY(-0.1rem);

            @include g.mq-down(md) {
              width: 3.9rem;
              height: 3.9rem;
              margin-right: 2.5rem;
            }

            &+.icon {
              margin-right: 0;


            }
          }

          .obj {
            transform-origin: center;
            transform: scale(0.7);

            @include g.mq-down(md) {
              transform: scale(0.6);
              margin: 0 1rem;
            }
          }
        }
      }

      .btns {
        padding: 10rem 0;

        .btn-box {
          display: block;
          margin: 0 auto;
          width: 42rem;
          text-align: center;

          figure {
            width: 4rem;
            margin: 0 auto 3rem;
          }

          .ja {
            margin-bottom: 3rem;
          }

          .en {
            font-weight: 700;
            font-size: 3.2rem;
            padding: 0.3rem 0;
            background: repeating-linear-gradient(-135deg, #ddd, #ddd 0.2rem, #fff 0.2rem, #fff 0.6rem);
          }
        }
      }

      .bottom {
        width: 100%;
        background: #000;
        padding: 3rem 0;
        position: absolute;
        bottom: 0;
        left: 0;

        span {
          display: block;
          width: 25rem;
          margin: 0 auto;
        }
      }
    }
  }
}
