@use "../global" as g;

#form {
  dl {
    display: flex;
    float: none;

    @include g.mq-down(md) {
      display: block;
    }

    &+dl {
      margin-top: 2rem;
    }

    dt {
      width: 20rem;
      padding-top: 0.5rem;

      @include g.mq-down(md) {
        width: 100%;
      }
    }

    dd {
      width: calc(100% - 20rem);

      @include g.mq-down(md) {
        width: 100%;
      }
    }
  }


  .must {
    display: inline;
    float: none;
    background: none;
    border-radius: 0;
    box-shadow: none;
    text-shadow: none;
    border: 0;
    background-color: #c83232;
    font-size: 1.2rem;

    @include g.mq-down(md) {
      font-size: 2.2rem;
    }
  }

  .mfp_buttons {
    text-align: right;
    padding: 0;
  }

  .confirm {
    background: #000000;
    border: none;
    padding: 0.8rem 10rem;
    color: #FFFFFF;
    font-size: 1.6rem;
    border-radius: 0;
    text-shadow: none;

    @include g.mq-down(md) {
      padding: 0.8rem 15rem;
      font-size: 3rem;
    }
  }

  .mfp_err {
    font-size: 1.2rem;
    margin: 0.3rem 0px 0px 0px;
    padding: 0.3rem 0px 0.5rem 0px;

    @include g.mq-down(md) {
      font-size: 2.4rem;
    }
  }

  input[type=text],
  input[type=tel],
  input[type=email],
  select,
  textarea {
    box-shadow: none;

  }

  input[type=text],
  input[type=tel],
  input[type=email] {
    width: 100% !important;
    max-width: 100%;
  }

  textarea {
    width: 100% !important;
    max-width: 100%;
    height: 20rem !important;
    margin: 0;

    @include g.mq-down(md) {
      height: 40rem !important;
    }
  }
}
