@use "../global" as g;

.contactpoint-sec {
  padding: 14rem 0 15rem;

  .inner {
    width: 128rem;
    margin: 0 auto;

    @include g.mq-down(md) {
      width: calc(100% - 7.2rem);
    }

    .btns {
      display: flex;
      justify-content: center;

      @include g.mq-down(md) {
        display: block;
      }

      .btn-box {
        display: block;
        width: 23rem;
        margin: 0 9.3rem;

        @include g.image-link();

        @include g.mq-down(md) {
          width: 39rem;
          margin: 0 auto;

          &+.btn-box {
            margin-top: 10rem;
          }
        }

        figure {
          width: auto;
          height: 3.6rem;
          margin: 0 auto 5rem;
          text-align: center;

          @include g.mq-down(md) {
            height: 4.8rem;
          }

          img {
            width: auto;
            height: 100%;
          }
        }

        .btn {
          width: 100%;
        }
      }
    }
  }
}
